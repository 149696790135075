import React from "react"
import {
  Section,
  SectionHero,
  Container,
  Hero,
  Image,
} from "../components/Components"

const Merchandising = ({ id }) => (
  <article id={id}>
    <SectionHero title="Merchandising" text="" />
    <Section>
      <Container>
        <div className="columns">
          <div className="column is-half">
            <Image
              src={require("../images/communication/merchandising/external.jpg")}
              alt="External"
              ratio="none"
            />
            <p>Hypebeast.com</p>
          </div>
          <div className="column is-half">
            <Image
              src={require("../images/communication/merchandising/internal.jpg")}
              alt="Internal"
              ratio="none"
            />
            <p>Yoox.com</p>
          </div>
        </div>
      </Container>
    </Section>
    {/* <Hero
      size="fullheight"
      classes={["hero-background", "is-light"]}
      // styleInline={{ backgroundImage: `url('${fullwidthImg}')` }}
    /> */}
  </article>
)

export default Merchandising
