import React from "react"
import {
  Section,
  SectionHero,
  Container,
  Hero,
  Image,
  Content,
} from "../components/Components"

const Services = ({ id }) => (
  <article id={id}>
    <SectionHero title="Services and Innovation" text="" />
    <Section>
      <Container>
        <div className="columns">
          <div className="column is-half">
            <Image
              src={require("../images/communication/services/service-ig.jpg")}
              alt="IG Stories"
              ratio="none"
            />
          </div>
          <div
            className="column is-one-third is-offset-2"
            style={{ paddingBottom: `45px` }}
          >
            <Image
              src={require("../images/communication/services/service-banner.jpg")}
              alt="Banner"
              ratio="none"
            />
          </div>
        </div>
      </Container>
    </Section>
    {/* <Hero
      size="fullheight"
      classes={["hero-background", "is-light"]}
      // styleInline={heroBackgroundImage}
    /> */}
  </article>
)

export default Services
