import React from "react"
import { Section, Container, Content, Image } from "../components/Components"

export default ({ id }) => (
  <article id={id}>
    <Section>
      <Container>
        <Content>
          <Image
            src={require("../images/communication/intro/timeline.jpg")}
            ratio="none"
            alt="Timeline"
          />
        </Content>
      </Container>
    </Section>
  </article>
)
