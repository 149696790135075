import React from "react"
import {
  Section,
  Container,
  Content,
  Hero,
  Image,
  SectionHero,
  Video,
} from "../components/Components"

const Purchase = ({ id }) => (
  <article id={id}>
    <SectionHero title="Purchase Occasion - Hunters" text="" />
    <Section>
      <Container>
        <div className="columns">
          <div className="column is-one-third is-offset-4">
            <Video
              src={require("../images/communication/purchase/occasion-ig.mp4")}
            />
          </div>
        </div>
      </Container>
    </Section>
    <SectionHero title="Purchase Occasion - Format" text="" />
    <Section>
      <Container>
        <Content>
          <Image
            src={require("../images/communication/purchase/bf-yoox.jpg")}
            ratio="none"
            alt="Black Friday Platform"
          />
        </Content>
        <div className="columns">
          {/* <div className="column is-one-third">
            <Image
              src={require("../images/communication/purchase/bf-banner.jpg")}
              alt="Black Friday Banner"
              ratio="none"
            />
            <p>Banner</p>
          </div> */}
          <div className="column is-two-third">
            <Image
              src={require("../images/communication/purchase/bf-marie.jpg")}
              alt="Black Friday Marie"
              ratio="none"
            />
          </div>
          <div className="column is-one-third">
            <Video
              src={require("../images/communication/purchase/bf-stories.mp4")}
              autoplay
            />
          </div>
        </div>
        <div className="columns" />
        <Content>
          <Image
            src={require("../images/communication/purchase/bf-newsletter.jpg")}
            ratio="none"
            alt="Black Friday Newsletter"
          />
        </Content>
      </Container>
    </Section>
    {/* <Hero
      size="fullheight"
      classes={["hero-background", "is-light"]}
      // styleInline={heroBackgroundImage}
    /> */}
  </article>
)

export default Purchase
