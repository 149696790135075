import React from "react"
import {
  Section,
  SectionHero,
  Container,
  Image,
} from "../components/Components"

const System = ({ id }) => (
  <article id={id}>
    <SectionHero title="Communication System" text="" />
    <Section>
      <Container>
        <Image
          src={require("../images/communication/system/timeline_totale.jpg")}
          alt="Communication"
          ratio="none"
        />
      </Container>
    </Section>
  </article>
)

export default System
