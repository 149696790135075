import React from "react"
import {
  Section,
  SectionHero,
  Container,
  Hero,
  Content,
  Image,
  Video,
} from "../components/Components"

const Party = ({ id }) => (
  <article id={id}>
    <SectionHero title="Branding Campaign" text="" />
    <Section>
      <Container>
        <Content>
          <Video src={require("../images/communication/the-party/party.mp4")} />
        </Content>
      </Container>
    </Section>
    {/* <Hero
      size="fullheight"
      classes={["hero-background"]}
      styleInline={{
        backgroundImage: `url('${require("../images/communication/the-party/you-billboard.jpg")}')`,
      }}
    /> */}
  </article>
)

export default Party
