import React from "react"

import { PageHero, Layout, Navigator } from "../components/Components"

import "../styles/style.scss"

import { navigation } from "../common/navigation"
import sections, {
  Intro,
  Party,
  Eight,
  Commercial,
  Special,
  Merchandising,
  Services,
  Purchase,
  Retail,
  Branded,
  System,
} from "../communication/sections"

const CommunicationPage = () => {
  return (
    <Layout>
      <PageHero
        title="Campaign Developing"
        menu={navigation}
        classes={["has-background-black", "is-dark"]}
      />
      <Navigator menu={sections} />
      <Intro id="intro" />
      <Party id="party" />
      {/* <Eight id="eight" /> */}
      <Branded id="branded" />
      <Commercial id="commercial" />
      <Special id="special" />
      <Merchandising id="merchandising" />
      <Services id="services" />
      <Purchase id="purchase" />
      <Retail id="retail" />
      <System id="system" />
    </Layout>
  )
}

export default CommunicationPage
