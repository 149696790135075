import React from "react"
import {
  Section,
  SectionHero,
  Container,
  Content,
  Hero,
  Image,
  Video,
} from "../components/Components"

const Special = ({ id }) => (
  <article id={id}>
    <SectionHero title="Special Projects" text="" />
    <Section>
      <Container>
        <Content>
          <Image
            src={require("../images/communication/special/ceremony-horiz.jpg")}
            alt="Adidas affissione verticale"
            ratio="none"
          />
        </Content>
        <div className="columns">
          <div className="column is-half">
            <Image
              src={require("../images/communication/special/ceremony-vert.jpg")}
              ratio="none"
              alt="Placeholder"
            />
          </div>
        </div>
        <Content>
          <Image
            src={require("../images/communication/special/ceremony-preroll.jpg")}
            alt="Timeline"
            ratio="none"
          />
        </Content>
        <div className="columns">
          <div className="column is-one-third is-offset-4">
            <Video
              src={require("../images/communication/special/ceremony-ig.mp4")}
              autoplay
            />
          </div>
        </div>
        <Content>
          <Image
            src={require("../images/communication/special/ceremony-timeline.jpg")}
            alt="Timeline"
            ratio="none"
          />
        </Content>
      </Container>
    </Section>
    <Hero
      size="fullheight"
      classes={["hero-background", "is-light"]}
      styleInline={{
        backgroundImage: `url("${require("../images/communication/special/ceremony-render.jpg")}")`,
      }}
    />
  </article>
)

export default Special
