import React from "react"
import {
  Section,
  SectionHero,
  Container,
  Hero,
  Image,
  Content,
} from "../components/Components"

const Retail = () => (
  <article id="composition">
    <SectionHero title="Retail and Promo" text="" />
    <Section>
      <Container>
        <Content>
          <Image
            src={require("../images/communication/promo/fb-carousel.jpg")}
            alt="FA Promo"
            ratio="none"
          />
        </Content>
      </Container>
    </Section>
    {/* <Hero
      size="fullheight"
      classes={["hero-background", "is-light"]}
      // styleInline={heroBackgroundImage}
    /> */}
  </article>
)

export default Retail
