import React from "react"
import {
  Section,
  SectionHero,
  Container,
  Hero,
  Content,
  Image,
  Video,
} from "../components/Components"

const Commercial = ({ id }) => (
  <article id={id}>
    <SectionHero title="Commercial Projects" text="" />
    <Section>
      <Container>
        <Content>
          <Image
            src={require("../images/communication/commercial/adidas-preroll.jpg")}
            alt="Timeline"
            ratio="none"
          />
          <Image
            src={require("../images/communication/commercial/adidas-orizziontale.jpg")}
            ratio="none"
            alt="Placeholder"
          />
        </Content>
        <div className="columns">
          <div className="column is-one-third is-offset-4">
            <Video
              src={require("../images/communication/commercial/adidas-ig.mp4")}
            />
          </div>
        </div>
        <Content>
          <Image
            src={require("../images/communication/commercial/adidas-timeline.jpg")}
            alt="Timeline"
            ratio="none"
          />
        </Content>
      </Container>
    </Section>
    <Hero
      size="fullheight"
      classes={["hero-background", "is-light"]}
      styleInline={{
        backgroundImage: `url('${require("../images/communication/commercial/adidas-affissione.jpg")}')`,
      }}
    />
  </article>
)

export default Commercial
