import React from "react"
import {
  Section,
  SectionHero,
  Container,
  Hero,
  Image,
  Content,
  Video,
} from "../components/Components"

const Branded = ({ id }) => (
  <article id={id}>
    <SectionHero title="Branded Content" />
    <Section>
      <Container>
        <Content>
          <Video src={require("../images/communication/branded/trailer.mp4")} />
        </Content>
        <div className="columns">
          <div className="column is-one-third is-offset-4">
            <Video
              src={require("../images/communication/branded/ig-style.mp4")}
            />
          </div>
        </div>
        <Content>
          <Image
            src={require("../images/communication/branded/mockup_emily_sito.jpg")}
            ratio="none"
            alt="Sito Emily"
          />
        </Content>
      </Container>
    </Section>
    <Hero
      size="fullheight"
      classes={["hero-background", "is-light"]}
      styleInline={{
        backgroundImage: `url('${require("../images/communication/branded/mockup_shawn_mendes.jpg")}')`,
      }}
    />
  </article>
)

export default Branded
