import Intro from "../communication/intro"
import Party from "../communication/party"
import Eight from "../communication/eight"
import Commercial from "../communication/commercial"
import Special from "../communication/special"
import Merchandising from "../communication/merchandising"
import Services from "../communication/services"
import Purchase from "../communication/purchase"
import Retail from "../communication/retail"
import Branded from "../communication/branded"
import System from "../communication/system"

const sections = [
  {
    id: "party",
    src: "",
    title: "Branding Campaign",
  },
  {
    id: "eight",
    src: "",
    title: "8 by YOOX",
  },
  {
    id: "branded",
    src: "",
    title: "Branded Content",
  },
  {
    id: "commercial",
    src: "",
    title: "Commercial Projects",
  },
  {
    id: "special",
    src: "",
    title: "Special Projects",
  },
  {
    id: "merchandising",
    src: "",
    title: "Merchandising",
  },
  {
    id: "services",
    src: "",
    title: "Services and Innovation",
  },
  {
    id: "purchase",
    src: "",
    title: "Purchase Occasion",
  },
  {
    id: "retail",
    src: "",
    title: "Retail and Promo",
  },
]
export default sections

export {
  Intro,
  Party,
  Eight,
  Commercial,
  Special,
  Merchandising,
  Services,
  Purchase,
  Retail,
  Branded,
  System,
}
